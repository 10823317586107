import React from "react";

import { FontType } from "../../../constants/Fonts";
import { Track } from "../../../types/domain/Track/Track";
import { Text } from "../../atoms/Text";
import { TrackCard, TrackCardPlaceHolder } from "../../organisms/TrackCard";
import { useHome } from "./hooks";
import { StyledTrackCardContainer } from "./style";

export interface HomeProps {
	tracks: Track[];
}

export const Home: React.FC = () => {
	const { tracks, transitToTrack } = useHome();
	return (
		<>
			<Text fontType={FontType.TITLE}>Upcoming ⚡</Text>
			<StyledTrackCardContainer>
				{tracks
					? tracks
							.filter((track) => track.startAt > new Date().getTime())
							.sort((a, b) => b.startAt - a.startAt)
							.map((track) => (
								<TrackCard
									key={track._id}
									track={track}
									handleClick={() => transitToTrack(track._id)}
								></TrackCard>
							))
					: [...Array(0)].map((_, i) => <TrackCardPlaceHolder key={i} />)}
			</StyledTrackCardContainer>
			<Text fontType={FontType.TITLE}>New Releases 🔥</Text>
			<StyledTrackCardContainer>
				{tracks
					? tracks
							.filter((track) => track.startAt <= new Date().getTime())
							.sort((a, b) => b.startAt - a.startAt)
							.map((track) => (
								<TrackCard
									key={track._id}
									track={track}
									handleClick={() => transitToTrack(track._id)}
								></TrackCard>
							))
					: [...Array(9)].map((_, i) => <TrackCardPlaceHolder key={i} />)}
			</StyledTrackCardContainer>
		</>
	);
};
