import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { apiClient } from "../../../lib/apiClient";
import { Track } from "../../../types/domain/Track/Track";
export const useHome = () => {
	const router = useRouter();
	const [tracks, setTracks] = useState<Track[]>();

	useEffect(() => {
		(async () => {
			const res = await apiClient.get("/tracks");
			if (!res.data) return;
			setTracks(res.data.tracks);
		})();
	}, []);

	const transitToTrack = useCallback(
		(trackId: string) => {
			router.push(`/track/${trackId}`);
		},
		[router]
	);

	return { tracks, transitToTrack };
};
