import { Home } from "../components/pages/Home";
import { NextPage } from "next";
import { Track } from "../types/domain/Track/Track";
import { Seo } from "../components/templates/Seo";
export interface HomePageProps {
	tracks: Track[];
}

const HomeWrapper: NextPage = () => {
	return (
		<>
			<Seo />
			<Home />
		</>
	);
};

//また使うかもしれないのでコメントアウト
/*
export const getServerSideProps: GetServerSideProps = async () => {
	const res = await apiClient.get<GetAllTracksResponseBody>("tracks");
	const data = res.data;
	if (!data) {
		return {
			notFound: true
		};
	}
	const props: HomePageProps = {
		tracks: data.tracks
	};
	return { props };
}; */

export default HomeWrapper;
