import styled from "@emotion/styled";
import tw from "twin.macro";

export const StyledTrackCardContainer = styled.div`
	${tw`flex flex-wrap gap-8 mt-8 mb-16`};
`;

export const StyledTypeContainer = styled.div`
	${tw`mb-12`}
`;

export const StyledTypeTitleContainer = styled.div`
	${tw`flex items-center mb-4`}
`;

export const StyledTypeCircle = styled.div<{ type: string }>`
	${tw`w-6 h-6 rounded-full border-solid border-12 border-blue-500 mr-4`}
	${({ type }) =>
		type === "community"
			? tw`border-community`
			: type === "crowdfunding"
			? tw`border-crowdfunding`
			: tw`border-license`}
`;

export const StyledTypesTitleWrapper = styled.div`
	${tw`mb-12`}
`;
